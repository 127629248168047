import { createBrowserRouter } from "react-router-dom";
import Layout from "../pages/home/layout/Layout";
import AppLayout from "../pages/app/layout/Layout";
import React, { Suspense } from "react";
import Home from "../pages/home/Home"; 
import Signup from "../pages/home/Signup";
import Logout from "../pages/app/Logout"; 
import StudyTopicDetails from "../pages/app/StudyTopicDetails";
import LayoutLogin from "../pages/home/layoutlogin/LayoutLogin";
import Google from "../pages/home/Google";
import AppHome from "../pages/app/AppHome";

const ChangePassword = React.lazy(() => import("../pages/app/ChangePassword"));
const Profile = React.lazy(() => import("../pages/app/Profile"));
const Topics = React.lazy(() => import("../pages/app/Topics"));
const StudyTopicForm = React.lazy(() => import("../pages/app/StudyTopicForm"));
const StudyTopicReviewUpdate = React.lazy(
  () => import("../pages/app/StudyTopicReviewUpdate"),
);
const ManageForm = React.lazy(() => import("../pages/app/ManageForm"));
const ManageStudy = React.lazy(() => import("../pages/app/ManageStudy"));
const ManageCourse = React.lazy(() => import("../pages/app/ManageCourse"));
const Recall = React.lazy(() => import("../pages/app/Recall"));
const States = React.lazy(() => import("../pages/app/States"));
const Memorized = React.lazy(() => import("../pages/app/Memorized"));
const Dashboard = React.lazy(() => import("../pages/app/Dashboard"));
const Mail = React.lazy(() => import("../pages/home/Mail"));
const PasswordUpdate = React.lazy(() => import("../pages/home/PasswordUpdate"));
const ForgotPassword = React.lazy(() => import("../pages/home/ForgotPassword"));
const Login = React.lazy(() => import("../pages/home/Login"));

export const HomeRoutes = createBrowserRouter([
  
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      }
    ],
  },
  {
    path: "/auth",
    element: <LayoutLogin />,
    children: [    
      {
        path: "/auth/sign-up",
        element: <Signup />,
      },
      {
        path: "/auth/login",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Login />,
          </Suspense>
        ),
      },
      {
        path: "/auth/logout",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Logout />,
          </Suspense>
        ),
      },
      {
        path: "/auth/forgot-password",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <ForgotPassword />,
          </Suspense>
        ),
      },
      {
        path: "/auth/mail/:id",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Mail />
          </Suspense>
        ),
      },
      {
        path: "/auth/password-update/:id",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <PasswordUpdate />
          </Suspense>
        ),
      },
      {
        path: "/auth/google/:id",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Google />
          </Suspense>
        ),
      }      
    ],
  },
  {
    path: "/app",
    element: <AppLayout />,
    children: [
      {
        path: "/app/",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Dashboard />
          </Suspense>
        ),
      },
      {
        path: "/app/dashboard",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Dashboard />
          </Suspense>
        ),
      },
      {
        path: "/app/home",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <AppHome />
          </Suspense>
        ),
      },
      {
        path: "/app/new-topic",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Dashboard />
          </Suspense>
        ),
      },
      {
        path: "/app/topics",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Topics />
          </Suspense>
        ),
      },
      {
        path: "/app/recall/:id",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <StudyTopicDetails />
          </Suspense>
        ),
      },

      {
        path: "/app/add-topic",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <StudyTopicForm />
          </Suspense>
        ),
      },
      {
        path: "/app/edit-topic/:id",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <StudyTopicForm />
          </Suspense>
        ),
      },
      {
        path: "/app/review-topic/:id",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <StudyTopicReviewUpdate />
          </Suspense>
        ),
      },
      {
        path: "/app/recalls",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Recall />
          </Suspense>
        ),
      },
      {
        path: "/app/memorized",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Memorized />
          </Suspense>
        ),
      },
      {
        path: "/app/states",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <States />
          </Suspense>
        ),
      },
      {
        path: "/app/manage-form",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <ManageForm />
          </Suspense>
        ),
      },
      {
        path: "/app/manage-study",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <ManageStudy />
          </Suspense>
        ),
      },
      {
        path: "/app/manage-course",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <ManageCourse />
          </Suspense>
        ),
      },
      {
        path: "/app/profile",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Profile />
          </Suspense>
        ),
      },
      {
        path: "/app/change-password",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <ChangePassword />
          </Suspense>
        ),
      },
    ],
  },
]);
